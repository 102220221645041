import { DEL_ITEM_PERMISSION, MAINTAIN_PERMISSION, PPV_ACTUALS_EXTRA_ITEMS } from "@kraftheinz/common";
import { M as MyUtil } from "./MyUtil.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Extra Items", "has-active": false, "has-filter-inactive": false, "create-button-text": "Add New" }, scopedSlots: _vm._u([{ key: "customFilter", fn: function() {
    return [_c("span", { staticClass: "d-flex mr-2 align-items-center h-100" }, [_vm._v("Show Extra Items for Country")]), _c("div", { staticClass: "country-filter mr-2" }, [_c("select-filter", { key: "Country", attrs: { "source": "countryId", "placeholder": "Country", "data-source": _vm.countries, "source-label": _vm.generateCountryLabel, "default-active-first-option": "" }, on: { "change": function($event) {
      return _vm.onSelectFilterChange($event, "CountryID");
    } } })], 1)];
  }, proxy: true }, { key: "action", fn: function(ref) {
    var edit = ref.edit;
    var record = ref.record;
    return [_vm.can(_vm.permissions.maintain) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Edit")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "mr-2", attrs: { "icon": "edit", "size": "small", "type": "link" }, on: { "click": function($event) {
      return edit(record);
    } } })], 1) : _vm._e(), _vm.can(_vm.permissions.del) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Delete")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "delete", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.onDel(record);
    } } })], 1) : _vm._e()];
  } }]) }, [_c("text-field", { key: "CountryCode", attrs: { "data-index": "countryCode", "title": "Country", "sorter": true } }), _c("text-field", { key: "Item", attrs: { "data-index": "item", "title": "Item Code", "sorter": true } }), _c("text-field", { key: "ItemDesc", attrs: { "data-index": "itemDesc", "title": "Item Desc", "sorter": true } }), _c("text-field", { key: "ItemClassDesc", attrs: { "data-index": "itemClassDesc", "title": "Item Class", "sorter": true } }), _c("text-field", { key: "SubClass", attrs: { "data-index": "subClass", "title": "Sub Class", "sorter": true } }), _c("text-field", { key: "ProcurementCategory", attrs: { "data-index": "procurementCategory", "title": "Procurement Category", "sorter": true } })], 1);
};
var staticRenderFns$1 = [];
var ExtraItems_vue_vue_type_style_index_0_scoped_true_lang = "";
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "ExtraItems",
  mixins: [MyUtil],
  inject: ["crud", "can"],
  data() {
    return {
      apiUrl: apiUrl$1,
      permissions: {
        del: DEL_ITEM_PERMISSION,
        maintain: MAINTAIN_PERMISSION
      }
    };
  },
  created() {
    this.getCountries(false);
    this.crud.setQueryString("CountryID", this.countryIdAU);
  },
  methods: {
    onDel(record) {
      this.$confirm({
        class: "list__modal-delete",
        icon: "delete",
        title: `Do you want to delete this Extra Item?`,
        content: `If you click Yes, you won\u2019t be able to undo this Delete operation. Are you sure you want delete this Extra Item?`,
        cancelText: "No",
        okText: "Yes",
        okType: "danger",
        onOk: this.onConfirmDelete(record)
      });
    },
    onConfirmDelete(record) {
      return () => {
        this.axios.delete(`${this.apiUrl}/ppv/extra-items`, {
          data: { CountryId: record.countryId, Item: record.item }
        }).then(() => {
          this.crud.fetchList();
          this.$notification["success"]({
            message: "Item Deleted!"
          });
        }).catch(() => {
          this.$notification["error"]({
            message: "Something went wrong, please try again!"
          });
        });
      };
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "adac8ba0", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ExtraItems = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "ppv.extra-items", "edit-route": "/ppv-actuals/extra-items/:id", "create-route": "/ppv-actuals/extra-items/create", "page": _vm.page } }, [_c("extra-items")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ExtraItems
  },
  data() {
    return {
      page: PPV_ACTUALS_EXTRA_ITEMS,
      apiUrl,
      itemsMenu: [
        {
          key: "ppv-actuals",
          title: "PPV Actuals",
          path: ""
        },
        {
          key: "extra-items",
          title: "Extra Items",
          path: "/ppv-actuals/extra-items"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
